import React, { useState, useEffect } from "react";
import { Button, Text, View, Image, TextInput } from "react-native";

import { hotjar } from 'react-hotjar';

import * as ImagePicker from "expo-image-picker";

hotjar.initialize(3063525, 6);

export default function App() {
  const [image, setImage] = useState<undefined | ImagePicker.ImageInfo>();
  const [analysis, setAnalysis] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState<undefined | boolean>();
  const [authRequested, setAuthRequested] = useState<boolean>(false);

  const [email, setEmail] = useState<undefined | string>();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });

    const code = (params as any).code;

    const asyncFn = async () => {
      if (code) {
        try {
          const res = await fetch(
            `https://emotionle.digitalkompass.workers.dev/login?code=${code}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const resBody = await res.json();
          localStorage.setItem("token", resBody.token);
          setIsAuthenticated(true);
        } catch (e) {
          localStorage.removeItem("token");
        }
      } else if (localStorage.getItem("token")) {
        setIsAuthenticated(true);
      }
    };

    asyncFn();
  }, []);

  const login = async () => {
    if (!email) {
      return;
    }

    await fetch(`https://emotionle.digitalkompass.workers.dev/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        remember: true,
      }),
    });

    setAuthRequested(true);
  };

  const logout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.cancelled) {
      setImage(result);
    }
  };

  const requestAnalysis = async () => {
    if (image) {
      const base64 = image.uri;

      try {
        const res = await fetch(
          "https://emotionle.digitalkompass.workers.dev/analyze",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              image: base64,
              token: localStorage.getItem("token"),
            }),
          }
        );

        const resBody = await res.json();
        setAnalysis({
          user: resBody.user,
          gender: resBody.Gender,
          ageRange: resBody.AgeRange,
          emotions: resBody.Emotions,
        });
      } catch (e) {
        setAnalysis(undefined);
      }
    }
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
        }}
      >
        {!isAuthenticated && <Button onPress={login} title="Request login" />}
        {isAuthenticated && <Button onPress={logout} title="Logout" />}
        {!isAuthenticated && (
          <TextInput
            style={{
              paddingLeft: '1em',
              flex: 1
            }}
            placeholder="your@email.tld"
            editable={!authRequested}
            onChangeText={setEmail}
          />
        )}
      </View>

      <View
        style={{
          flex: 1,
          marginVertical: '2em'
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 32, fontWeight: 'bold' }}>
          Emotionle
        </Text>

        <Text style={{ textAlign: "center" }}>
          If you take a selfie or upload one, and click the Get Details button; it should give you back your dominant mood.
        </Text>
      </View>

      <View>
        {authRequested && <Text style={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}>Please check your {email} inbox for the authenticated link. <br /> It is now safe to close this window.</Text>}
      </View>

      {!authRequested && <View
        style={{
          flex: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button disabled={!isAuthenticated && analysis?.user.entries >= 3} title="Pick an image from camera roll" onPress={pickImage} />
        {analysis?.user.entries >= 3 && <Text>You are limited to 3 entries per day</Text>}

        <View
          style={{
            display: "flex",
            height: 512,
            width: 512,
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        >
          {image && (
            <Image
              source={{ uri: image.uri }}
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </View>
      </View>}

      <View
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {analysis && (
          <>
            <Text>
              Entries today <Text>{analysis.user.entries} / 3</Text>
            </Text>
            <Text>
              Emotions:{" "}
              <Text>
                {analysis.emotions
                  .filter((e: any) => e.Confidence >= 35)
                  .map((e: any) => e.Type)
                  .join(", ")}
              </Text>
            </Text>
          </>
        )}
      </View>

      <View>
        <Button
          disabled={!image || !isAuthenticated || analysis?.user.entries >= 3}
          onPress={requestAnalysis}
          title="Get details"
        />
      </View>
    </View>
  );
}
